@keyframes pulsestates {
  from {
    transform: scale(1);
    opacity: 0.4;
  }

  to {
    transform: scale(1.05);
    opacity: 1;
  }
}

.pulse {
  animation-duration: 1s;
  animation-name: pulsestates;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.17, 0.52, 1, 1);
}

.pulse-bg {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
}

.rotatable {
  transition: rotate 2s;
}

.rotated {
  transform: rotate(180deg);
}
